import React, { useState, useEffect } from "react";

const commands = {
  print: "Prints the resume",
  resume: "Open resume in a new tab",
  "aws certificate": "Open AWS certification",
  contact: "Displays contact information",
  linkedin: "Open LinkedIn profile",
  leetcode: "Open LeetCode profile",
  github: "Open GitHub profile",
  exit: "Close the command line tool",
};

const CommandLineTool = ({ onClose, triggerPrint }) => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState([]);
  const [suggestions, setSuggestions] = useState(Object.keys(commands));
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (suggestions.length > 0) {
        const selectedCommand = suggestions[selectedSuggestion];
        setInput("");
        processCommand(selectedCommand);
        setSelectedSuggestion(0);
        setSuggestions(Object.keys(commands));
      } else if (input.trim()) {
        processCommand(input.trim());
        setInput("");
        setSelectedSuggestion(0);
        setSuggestions(Object.keys(commands));
      }
    } else if (e.key === "Tab") {
      e.preventDefault();
      if (suggestions.length > 0) {
        setInput(suggestions[selectedSuggestion]);
        setSuggestions([]);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedSuggestion((prev) => (prev < suggestions.length - 1 ? prev + 1 : 0));
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedSuggestion((prev) => (prev > 0 ? prev - 1 : suggestions.length - 1));
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    if (value) {
      const filteredSuggestions = Object.keys(commands).filter((command) => command.startsWith(value));
      setSuggestions(filteredSuggestions);
      setSelectedSuggestion(0);
    } else {
      setSuggestions(Object.keys(commands));
    }
  };

  const processCommand = (command) => {
    const newOutput = [...output, `> ${command}`];
    if (command in commands) {
      newOutput.push(executeCommand(command));
    } else {
      newOutput.push(`Command not recognized: "${command}". Please try again.`);
    }
    setOutput(newOutput);
  };

  const executeCommand = (command) => {
    switch (command) {
      case "contact":
        return "erdenebayr.d@gmail.com";
      case "linkedin":
        window.open("https://www.linkedin.com/in/erdenebayar-dovchindorj/", "_blank");
        return "Opening LinkedIn profile...";
      case "github":
        window.open("https://github.com/erdenebayrd", "_blank");
        return "Opening GitHub profile...";
      case "leetcode":
        window.open("https://leetcode.com/u/dovchindorj/", "_blank");
        return "Opening LeetCode profile...";
      case "aws certificate":
        window.open("https://www.credly.com/badges/584dcd8a-5ec3-4fcc-9d52-f70d167fa49b", "_blank");
        return "Opening AWS certificate...";
      case "resume":
        window.open(`${process.env.PUBLIC_URL}/resume.pdf`, "_blank");
        return "Opening resume...";
      case "print":
        triggerPrint();
        return "Printing resume...";
      case "exit":
        onClose();
        return "Closing command line tool...";
      default:
        return "";
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInput("");
    processCommand(suggestion);
    setSelectedSuggestion(0);
    setSuggestions(Object.keys(commands));
  };

  const handleMouseEnter = (index) => {
    setSelectedSuggestion(index);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        e.preventDefault(); // Prevent default action
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-95 text-white p-4 z-50">
      <div className="overflow-auto h-full">
        {output.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
        <div className="flex">
          <span className="mr-2">&gt;</span>
          <input
            className="bg-transparent outline-none flex-1"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            autoFocus
          />
        </div>
        <div className="bg-gray-900 p-2 mt-2">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className={`p-1 cursor-pointer ${index === selectedSuggestion ? "bg-gray-700" : "hover:bg-gray-700"}`}
              onClick={() => handleSuggestionClick(suggestion)}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              {suggestion}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommandLineTool;
