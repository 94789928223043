import React from "react";

import Education from "./Education";

const Educations = () => {
  const educations = [
    {
      companyName: "Sydney Polytechnic Institute",
      position: "Master of Data Science",
      date: "2024 - 2026",
      description: "",
    },
    {
      companyName: "Mongolian University of Science and Technology",
      position: "Bachelor of Computer Science",
      date: "2014 - 2018",
      description: "",
    },
  ];

  return (
    <section id="educations" className="">
      <div className="container mx-auto space-y-2">
        <div className="flex items-center">
          <div className="flex-grow space-y-2">
            <p className="font-bold text-2xl">Education</p>
          </div>
        </div>
        <div className="space-y-2">
          {educations.map((experience, index) => (
            <Education key={index} {...experience} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Educations;
