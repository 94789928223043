import React from "react";

const About = () => {
  return (
    <section id="about" className="">
      <div className="container mx-auto">
        <div className="flex items-center">
          <div className="flex-grow space-y-2">
            <p className="font-bold text-2xl">About</p>
            <p className="text-sm text-custom-gray">
              With over 6 years of experiences as a software engineer, driven by solving impactful problems and learning
              new techs. Skilled in Python and creating microservices on AWS and Google Cloud, building scalable
              systems. Certified as an AWS Developer-Associate, proficient in Apache Airflow and Spark for big data
              projects, transforming data into actionable insights for decision-making.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
