import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Hero from "./components/Hero";
import About from "./components/About";
import Experiences from "./components/Experiences";
import Educations from "./components/Educations";
import Skills from "./components/Skills";
import CommandLineTool from "./components/CommandLineTool";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  const [showCommandLine, setShowCommandLine] = useState(false);
  const [platform, setPlatform] = useState("");
  const [triggerPrint, setTriggerPrint] = useState(false);

  useEffect(() => {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    setPlatform(isMac ? "Mac" : "Other");

    const handleKeyDown = (e) => {
      if ((isMac && e.metaKey && e.key === "j") || (!isMac && e.ctrlKey && e.key === "j")) {
        e.preventDefault(); // Prevent default action
        setShowCommandLine(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (triggerPrint) {
      setShowCommandLine(false); // Close the command line tool
    }
  }, [triggerPrint]);

  useEffect(() => {
    if (!showCommandLine && triggerPrint) {
      window.print();
      setTriggerPrint(false);
    }
  }, [showCommandLine, triggerPrint]);

  const MainContent = () => (
    <div className="antialiased min-h-screen flex justify-center py-10 px-5">
      <main className="pt-0 w-full max-w-[8.27in] mx-auto space-y-5">
        <Hero />
        <About />
        <Experiences />
        <Educations />
        <Skills />
        <br />
        <div
          className="fixed bottom-0 left-0 w-full bg-gray-900 text-white p-2 flex justify-center cursor-pointer items-center print:hidden"
          onClick={() => setShowCommandLine(true)}
        >
          <FontAwesomeIcon icon={faKeyboard} className="mr-2" />
          Press {platform === "Mac" ? "Command (⌘)" : "Ctrl"} + J to open command line
        </div>
        {showCommandLine && (
          <CommandLineTool onClose={() => setShowCommandLine(false)} triggerPrint={() => setTriggerPrint(true)} />
        )}
      </main>
    </div>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
