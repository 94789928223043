import React from "react";

const Experience = (props) => {
  const { companyName, position, date, description } = props;

  return (
    <div className="container mx-auto">
      <div className="flex items-center">
        <div className="flex-grow">
          <p className="text-sm">{companyName}</p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex-grow">
          <p className="font-bold">{position}</p>
        </div>
        <div className="flex-none flex">
          <p className="text-custom-gray text-sm ">{date}</p>
        </div>
      </div>
      <p className="text-custom-gray text-sm ">{description}</p>
    </div>
  );
};

export default Experience;
