import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas, faLink } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLinkedin, faGithub, faAws } from "@fortawesome/free-brands-svg-icons";
import cn from "classnames";

import LeetCodeIcon from "./LeetCodeIcon";

const Hero = () => {
  const iconClass = "h-4 w-4 text-gray-500";

  const [emailHover, setEmailHover] = useState(false);
  const [githubHover, setGithubHover] = useState(false);
  const [linkedinHover, setLinkedinHover] = useState(false);
  const [leetcodeHover, setLeetcodeHover] = useState(false);
  const [awsHover, setAwsHover] = useState(false);
  const [resumeHover, setResumeHover] = useState(false); // Added state for resume hover

  return (
    <section id="hero" className="">
      <div className="container mx-auto space-y-2">
        <p className="font-bold text-2xl">Erdenebayar Dovchindorj</p>
        <div className="flex items-center gap-2">
          {/* First Column: 3 Rows */}
          <div className="flex-grow space-y-2">
            <div className="">
              <p className="max-w-md text-custom-gray text-sm">
                A software engineer with a strong passion for solving complex challenges, bringing meaningful impact,
                and enthusiastic about continuously learning and mastering new technologies.
              </p>
              <div className="flex items-center mt-0.5">
                <FontAwesomeIcon icon={faGlobeAmericas} className="h-3 w-3 mr-2" />
                <p className="max-w-md  text-sm text-custom-gray">Sydney, Australia</p>
              </div>
            </div>
            {/* Replace with your actual print content */}
            <div className="print:block hidden">
              <div className="flex items-center gap-4">
                <div>
                  <div>
                    <p className="max-w-md text-custom-gray text-sm">erdenebayr.d@gmail.com</p>
                  </div>
                  <div>
                    <p className="max-w-md text-custom-gray text-sm">https://www.erdene.com.au</p>
                  </div>
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/aws.png`}
                  alt="aws certified developer-associate"
                  className="w-16 h-16"
                />
              </div>
            </div>

            <div className="flex items-center space-x-2 print:hidden">
              {/* email */}
              <a
                onMouseEnter={() => setEmailHover(true)}
                onMouseLeave={() => setEmailHover(false)}
                href="mailto:erdenebayr.d@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  "flex items-center justify-center w-8 h-8 rounded-md border border-gray-300",
                  emailHover && "bg-gray-100 transition-colors duration-300 ease-in-out"
                )}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className={cn(iconClass, emailHover && "text-gray-900 transition-colors duration-300 ease-in-out")}
                />
              </a>

              {/* github */}
              <a
                onMouseEnter={() => setGithubHover(true)}
                onMouseLeave={() => setGithubHover(false)}
                href="https://github.com/erdenebayrd"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  "flex items-center justify-center w-8 h-8 rounded-md border border-gray-300",
                  githubHover && "bg-gray-100 transition-colors duration-300 ease-in-out"
                )}
              >
                <FontAwesomeIcon
                  icon={faGithub}
                  className={cn(iconClass, githubHover && "text-gray-900 transition-colors duration-300 ease-in-out")}
                />
              </a>

              {/* linkedin */}
              <a
                onMouseEnter={() => setLinkedinHover(true)}
                onMouseLeave={() => setLinkedinHover(false)}
                href="https://www.linkedin.com/in/erdenebayar-dovchindorj/"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  "flex items-center justify-center w-8 h-8 rounded-md border border-gray-300",
                  linkedinHover && "bg-gray-100 transition-colors duration-300 ease-in-out"
                )}
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className={cn(iconClass, linkedinHover && "text-gray-900 transition-colors duration-300 ease-in-out")}
                />
              </a>

              {/* leetcode */}
              <a
                onMouseEnter={() => setLeetcodeHover(true)}
                onMouseLeave={() => setLeetcodeHover(false)}
                href="https://leetcode.com/u/dovchindorj/"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  "flex items-center justify-center w-8 h-8 rounded-md border border-gray-300",
                  leetcodeHover && "bg-gray-100 transition-colors duration-300 ease-in-out"
                )}
              >
                <LeetCodeIcon
                  className={cn(iconClass, leetcodeHover && "text-gray-900 transition-colors duration-300 ease-in-out")}
                />
              </a>

              {/* AWS Developer Associate */}
              <a
                onMouseEnter={() => setAwsHover(true)}
                onMouseLeave={() => setAwsHover(false)}
                href="https://www.credly.com/badges/584dcd8a-5ec3-4fcc-9d52-f70d167fa49b"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  "flex items-center justify-center w-8 h-8 rounded-md border border-gray-300",
                  awsHover && "bg-gray-100 transition-colors duration-300 ease-in-out"
                )}
              >
                <FontAwesomeIcon
                  icon={faAws} // Make sure you have the AWS icon imported
                  className={cn(iconClass, awsHover && "text-gray-900 transition-colors duration-300 ease-in-out")}
                />
              </a>

              {/* Resume */}
              <a
                onMouseEnter={() => setResumeHover(true)}
                onMouseLeave={() => setResumeHover(false)}
                href={`${process.env.PUBLIC_URL}/resume.pdf`}
                target="_blank" // Opens the resume in a new tab
                rel="noopener noreferrer"
                className={cn(
                  "flex items-center justify-center px-2 h-8 rounded-md border border-gray-700",
                  resumeHover && "bg-gray-100 transition-colors duration-300 ease-in-out"
                )}
              >
                <FontAwesomeIcon
                  icon={faLink}
                  className={cn(iconClass, resumeHover && "text-gray-900 transition-colors duration-300 ease-in-out")}
                />
                <span className="ml-1 text-sm text-black">Resume</span>
              </a>
            </div>
          </div>

          {/* Second Column: Image */}
          <div className="hidden sm:block flex-none max-h-28 max-w-28 rounded-xl overflow-hidden">
            <img src="https://avatars.githubusercontent.com/u/31505361?v=4" alt="Placeholder" className="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
