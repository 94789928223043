import React from "react";

const Skills = () => {
  const skills = [
    { skill: "Python" },
    { skill: "AWS" },
    { skill: "Google Cloud" },
    { skill: "SQL" },
    { skill: "Docker" },
    { skill: "C++" },
    { skill: "Airflow" },
    { skill: "Spark" },
    { skill: "ReactJS" },
    { skill: "Firebase" },
    { skill: "LLM" },
    { skill: "NoSQL" },
    { skill: "Prompt Engineering" },
    { skill: "Node.js" },
  ];

  return (
    <section id="skills" className="">
      <div className="container mx-auto space-y-2">
        <div className="flex items-center">
          <div className="flex-grow space-y-2">
            <p className="font-bold text-2xl">Skills</p>
          </div>
        </div>
        <div className="flex flex-wrap gap-1">
          {skills.map((detail, index) => (
            <Skill key={index} {...detail} />
          ))}
        </div>
        <div>
          <p className="text-custom-gray text-sm">
            During undergraduate studies, participated in online coding competitions on platforms such as Codeforces,
            LeetCode and Hackerrank led to proficiency in learning and implementing both basic and advanced algorithms
            and data structures.
          </p>
        </div>
      </div>
    </section>
  );
};

const Skill = (props) => {
  const { skill } = props;

  return (
    <div className="bg-gray-700 px-3 rounded">
      <p className="text-white text-sm font-bold">{skill}</p>
    </div>
  );
};

export default Skills;
