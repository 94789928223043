import React from "react";

import Experience from "./Experience";

const Experiences = () => {
  const experiences = [
    {
      companyName: "Core Tech LLC",
      position: "Senior Data Engineer",
      date: "2023 - Present",
      description:
        "Working on big data project focusing on migrating sales data of a store chain to NoSQL DB for analysis and insights, aiming to boost sales. Automated data collection and processing by implementing Apache Airflow and Spark within a Docker, transitioning from a manual system.",
    },
    {
      companyName: "Dentsu Data Artist Mongol LLC",
      position: "Senior Software Engineer",
      date: "2018 - 2023",
      description:
        "Leading a team in the crowdsourcing projects, creating comprehensive systems with AI models. Architected scalable microservices on cloud platforms, ensuring high availability and flexibility. Enhanced data processing capabilities by optimizing algorithms and employing advanced data structures which reduces time complexity and resource utilization.",
    },
  ];

  return (
    <section id="experiences" className="">
      <div className="container mx-auto space-y-2">
        <div className="flex items-center">
          <div className="flex-grow space-y-2">
            <p className="font-bold text-2xl">Experience</p>
          </div>
        </div>
        <div className="space-y-7">
          {experiences.map((experience, index) => (
            <Experience key={index} {...experience} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experiences;
