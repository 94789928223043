import React from "react";

const PrivacyPolicy = () => (
  <div className="privacy-policy bg-gray-100 min-h-screen">
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="px-6 py-8 sm:p-10">
          <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">SPI TAP Privacy Policy</h1>
          <p className="text-sm text-gray-600 mb-6">Effective Date: Oct 1, 2024</p>

          <div className="space-y-6 text-gray-700">
            <p>
              This Privacy Policy describes how <strong>SPI TAP</strong> ("we", "us", or "our") collects, uses, and
              discloses your personal information when you use our mobile application (the "App"). This App is intended
              exclusively for Sydney Polytechnic Institute students to register their attendance in classes.
            </p>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">1. Information We Collect</h2>

              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-2">1.1 Personal Information</h3>
              <p>
                <strong>Student Email Address:</strong> When you log in to the App using your student email, we collect
                your email address to verify your identity and grant access to the App's features. Authentication is
                handled via Firebase Auth.
              </p>

              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-2">1.2 Attendance Data</h3>
              <p>
                <strong>NFC Tag Interactions:</strong> We collect data when you tap NFC tags in classrooms, including
                the time of entry and exit, to record your attendance.
              </p>

              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-2">1.3 Device Information</h3>
              <p>
                <strong>Device Information:</strong> We may collect information about the device you use to access the
                App, such as mobile device ID, operating system, and version.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">2. How We Use Your Information</h2>
              <p>We use the collected information for the following purposes:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>
                  <strong>Authentication:</strong> To verify your student status and provide access to the App.
                </li>
                <li>
                  <strong>Attendance Tracking:</strong> To record and manage your class attendance for Sydney
                  Polytechnic Institute.
                </li>
                <li>
                  <strong>Communication:</strong> To send you important updates or information regarding the App and
                  your attendance.
                </li>
                <li>
                  <strong>Compliance:</strong> To comply with legal obligations and institute policies.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">3. Disclosure of Your Information</h2>
              <p>We may share your information with third parties in the following situations:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>
                  <strong>Educational Institution:</strong> We share attendance data with Sydney Polytechnic Institute
                  for administrative and academic purposes.
                </li>
                <li>
                  <strong>Service Providers:</strong> We engage third-party services like Firebase for authentication
                  and data storage. These providers have access to your information only to perform tasks on our behalf.
                </li>
                <li>
                  <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or
                  in response to valid requests by public authorities.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">4. Data Security</h2>
              <p>
                We prioritize the security of your personal information. We implement industry-standard security
                measures to protect your data against unauthorized access, alteration, disclosure, or destruction.
                However, please note that no method of transmission over the internet is 100% secure.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">5. Data Retention</h2>
              <p>
                We retain your personal information only for as long as is necessary for the purposes set out in this
                Privacy Policy and to the extent necessary to comply with our legal obligations.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">6. Your Rights</h2>
              <p>Under applicable laws, you may have the right to:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Access the personal information we hold about you.</li>
                <li>Request correction of inaccurate or incomplete data.</li>
                <li>Request deletion of your personal information.</li>
                <li>Object to or request the restriction of processing your personal information.</li>
              </ul>
              <p>To exercise these rights, please contact us using the details provided below.</p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">7. Children's Privacy</h2>
              <p>
                Our App is intended for use by students of Sydney Polytechnic Institute. We do not knowingly collect
                personal information from anyone under the age of 16 without parental consent. If you are a parent or
                guardian and believe your child has provided us with personal information, please contact us.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">8. Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy periodically. We will notify you of any changes by posting the new
                Privacy Policy on this page and updating the effective date. We recommend reviewing this Privacy Policy
                periodically for any changes.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">9. Contact Us</h2>
              <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
              <p className="mt-2">
                <strong>Email:</strong> spi230957@stud.spi.nsw.edu.au
              </p>
              <p>
                <strong>Address:</strong> Sydney Polytechnic Institute, Level 4, 191 Thomas Street, Haymarket, Sydney
                NSW 2000, Australia
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
